<template>
  <div class="p-3">
    <h5>業務清單</h5>

    <customer-staff-operator
      :filter="filter"
      :operator="operator"
      :boundTypes="boundTypes"
      @trigger-filter="getCustomerStaffs()"
      @trigger-change-staff="showChangeStaff()"
      @trigger-unbind="showUnbindStaff()"
      @trigger-change-bound-type="showChangeBoundType()"
      class="mb-3"
      :textMapping="textMapping"
    />

    <div class="row">
      <div class="col-12 row d-flex mb-4 mb-xl-2 justify-content-between flex-row-reverse">
        <div
          class="
            col-12 col-xl-2 col-start-12
            xl:col-start-1
            d-flex
            flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <CustomerAddStaff
            v-if="checkPermission([consts.CUSTOMER_STAFF_BIND_STAFF])"
            :customer="customer"
            @added="
              getCustomerStaffs();
            "
          ></CustomerAddStaff>
        </div>
      </div>
      <div class="col-12">
        <b-table
          striped
          hover
          responsive
          :items="customerStaffs"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy="showLoading"
        >
          <template #head(id)="">
            <b-form-checkbox @change="handleSelectAll" v-model="isSelectAll">
            </b-form-checkbox>
          </template>
          <template #cell(id)="data">
            <b-form-checkbox
              :value="data.item.pivot_id"
              v-model="selectedPivotIds"
              :disabled="!doesBound"
              @change="handleChecboxChange"
            />
          </template>
          <template #cell(avatar_url)="data">
            <div class="d-flex align-items-center justify-content-center">
              <b-avatar
                :src="data.item.avatar_url"
                variant="secondary"
                size="2rem"
              ></b-avatar>
            </div>
          </template>
          <template #cell(bound_type)="data">
            {{ mapBoundTypeName(data.item.bound_type) }}
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import format from "date-fns/format";
import customerApi from "@/apis/customer";
import CustomerStaffOperator from "@/components/CustomerStaffOperator";
import organizationApi from "@/apis/organization";
import "bootstrap-vue/dist/bootstrap-vue.css";
import CustomerAddStaff from "@/pages/Dashboard/Customer/CustomerAddStaff.vue";
import * as consts from '@/consts'
import PermissionChecker from '@/utils/PermissionChecker'

export default {
  components: { CustomerStaffOperator, CustomerAddStaff },
  data() {
    return {
      customer: {},
      customerStaffs: [],
      staffs: [],
      filter: {
        doesBound: true,
        selectedBoundTypes: [],
        keyword: null,
      },
      operator: {
        actionMode: "change-staff",
        changeToStaffId: null,
        changeBoundType: null,
      },
      selectedPivotIds: [],
      text: "{customers.data}",
      doesBound: true,
      bindOptions: [
        { text: "目前業務", value: true },
        { text: "綁定紀錄", value: false },
      ],
      boundTypes: [],
      showLoading: false,
      isSelectAll: false,
      sortBy: null,
      sortDesc: null,
      fields: [
        {
          key: "id",
          label: "勾選",
        },
        {
          key: "avatar_url",
          label: "",
        },
        {
          key: "employee_code",
          label: "員工編號",
        },
        {
          key: "name",
          label: "姓名",
          sortable: true,
        },
        {
          key: "job_level",
          label: "職稱",
        },
        {
          key: "service_unit",
          label: "單位",
        },
        {
          key: "dept_name",
          label: "部門",
        },
        {
          key: "bound_at",
          label: "綁定日期",
          sortable: true,
        },
        {
          key: "unbound_at",
          label: "解綁日期",
          sortable: true,
        },
        {
          key: "bound_type",
          label: "綁定類型",
          sortable: true,
        },
      ],
      textMapping: [],
      consts,
    };
  },
  computed: {
    ...mapGetters("general", ["organization", "role"]),
  },
  async created() {
    await this.getSaleConfigsModule();
    this.getCustomer();
    this.getCustomerStaffs();
  },
  methods: {
    async getCustomer() {
      try {
        const { data } = await customerApi.getCustomer(
          this.$route.params.customerID
        );

        this.customer = {
          ...data,
          join_datetime:
            data.join_datetime &&
            format(new Date(data.join_datetime), "yyyy-MM-dd HH:mm"),
          leave_datetime:
            data.leave_datetime &&
            format(new Date(data.leave_datetime), "yyyy-MM-dd HH:mm"),
          created_at: format(new Date(data.created_at), "yyyy-MM-dd HH:mm"),
          updated_at: format(new Date(data.updated_at), "yyyy-MM-dd HH:mm"),
        };
        this.text = JSON.stringify(data.data);
      } catch (e) {
        console.log(e);
      }
    },
    async getCustomerStaffs() {
      this.showLoading = true;
      try {
        const { data } = await customerApi.getStaffs(
          this.$route.params.customerID,
          {
            doesBound: this.filter.doesBound,
            selectedBoundTypes: this.filter.selectedBoundTypes.map(
              (type) => type.value
            ),
            keyword: this.filter.keyword,
          }
        );
        this.customerStaffs = data.data;
      } catch (e) {
        console.log(e);
      }
      this.showLoading = false;
    },
    async getSaleConfigsModule() {
      try {
        const { data } = await organizationApi.getModuleConfig(
          this.organization.id,
          { module_code: "sales-config" }
        );

        if (data.bound_type_filter) {
          let rolesBoundType = data.bound_type_filter.roles[this.role];
          if (!rolesBoundType) {
            rolesBoundType = data.bound_type_filter.default;
          }

          const boundTypes = data.bound_type_filter.bound_type.filter(
            (boundType) => {
              // * 代表全部都過
              if (rolesBoundType.includes("*")) {
                return true;
              } else {
                return rolesBoundType.includes(boundType.key);
              }
            }
          );

          this.boundTypes = boundTypes;

          this.filter.selectedBoundTypes = boundTypes
            .filter((boundType) => {
              return boundType.default_selected;
            })
            .map((boundType) => {
              return { value: boundType.key, text: boundType.display_name };
            });

          if (data.text_mapping) {
            this.textMapping = data.text_mapping;
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    showChangeStaff() {
      if (!this.validateSelectStaff()) return;
      if (!this.operator.changeToStaffId) {
        this.$swal.fire({
          title: "請選擇轉移業務",
          type: "error",
        });
        return;
      }

      this.$swal({
        title: "確定要轉移業務嗎？",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "確定轉移",
        cancelButtonText: "返回",
        reverseButtons: true,
        cancelButtonColor: "#fff",
        confirmButtonClass: "btn btn-lg btn-primary m-1 h3",
        cancelButtonClass: "btn btn-lg btn-outline-primary m-1 h3",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          await this.changeStaff();
        }
      });
    },
    async changeStaff() {
      try {
        const response = await customerApi.changeStaff(
          this.$route.params.customerID,
          {
            from_pivot_id: this.selectedPivotIds[0],
            to_staff_id: this.operator.changeToStaffId,
          }
        );
        this.selectedPivotIds = [];

        if (response.status === 200) {
          this.$swal.fire({
            title: "成功",
            type: "success",
            text: `轉移成功`,
          });
          this.getCustomerStaffs();
          this.isSelectAll = false;
        } else {
          if (response.data.message) {
            this.$swal.fire({
              title: "錯誤",
              type: "error",
              text: response.data.message,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    showUnbindStaff() {
      if (!this.validateSelectStaff()) return;

      this.$swal({
        title: "確定要解除綁定嗎？",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "解除綁定",
        cancelButtonText: "返回",
        reverseButtons: true,
        cancelButtonColor: "#fff",
        confirmButtonClass: "btn btn-lg btn-danger m-1 h3",
        cancelButtonClass: "btn btn-lg btn-outline-primary m-1 h3",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          await this.unbindStaff();
        }
      });
    },
    async unbindStaff() {
      let staffs = this.customerStaffs.filter((staff) => {
        return this.selectedPivotIds.includes(staff.pivot_id);
      });

      try {
        const response = await customerApi.unbindStaff(
          this.$route.params.customerID,
          { pivot_ids: staffs.map((staff) => staff.pivot_id) }
        );
        this.selectedPivotIds = [];

        if (response.status === 200) {
          const name = staffs.map((staff) => staff.name).join(",");
          this.$swal.fire({
            title: "成功",
            type: "success",
            text: `${name} 解綁成功`,
          });
          this.getCustomerStaffs();
        } else {
          if (response.data.message) {
            this.$swal.fire({
              title: "錯誤",
              type: "error",
              text: response.data.message,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    validateSelectStaff() {
      let warning = "";
      switch (true) {
        case this.operator.actionMode == "change-staff" &&
          this.selectedPivotIds[0] == this.operator.changeToStaffId:
          warning = "轉移與被轉移業務相同，無法轉移";
          break;
        case this.operator.actionMode == "change-staff" &&
          this.selectedPivotIds.length != 1:
          warning = "請勾選業務名單/只能選擇一筆業務";
          break;
        case this.selectedPivotIds.length == 0:
          warning = "請勾選業務名單";
          break;
      }

      if (warning) {
        this.$swal.fire({
          title: warning,
          type: "error",
        });
        return false;
      }
      return true;
    },
    handleSelectAll(value) {
      if (value) {
        this.selectedPivotIds = this.customerStaffs.map(
          (staff) => staff.pivot_id
        );
      } else {
        this.selectedPivotIds = [];
      }
    },
    handleChecboxChange() {
      if (
        this.selectedPivotIds.length == this.customerStaffs.length &&
        this.customerStaffs.length != 0
      ) {
        this.isSelectAll = true;
      }
      if (
        this.selectedPivotIds.length != this.customerStaffs.length ||
        this.customerStaffs.length == 0
      ) {
        this.isSelectAll = false;
      }
    },
    mapBoundTypeName(boundTypeKey) {
      return this.boundTypes.find((boundType) => boundType.key == boundTypeKey)
        ?.display_name;
    },
    showChangeBoundType() {
      if (!this.validateSelectStaff()) return;
      if (!this.operator.changeBoundType) {
        this.$swal.fire({
          title: "請選擇變更類型",
          type: "error",
        });
        return;
      }

      this.$swal({
        title: "確定要變更類型嗎？",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "確定變更",
        cancelButtonText: "返回",
        reverseButtons: true,
        cancelButtonColor: "#fff",
        confirmButtonClass: "btn btn-lg btn-primary m-1 h3",
        cancelButtonClass: "btn btn-lg btn-outline-primary m-1 h3",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          await this.changeBoundType();
        }
      });
    },
    async changeBoundType() {
      try {
        const response = await customerApi.changeBoundType(
          this.$route.params.customerID,
          {
            pivot_ids: this.selectedPivotIds,
            bound_type: this.operator.changeBoundType,
          }
        );
        this.selectedPivotIds = [];

        if (response.status === 200) {
          this.$swal.fire({
            title: "成功",
            type: "success",
            text: `變更成功`,
          });
          this.getCustomerStaffs();
          this.isSelectAll = false;
        } else {
          if (response.data.message) {
            this.$swal.fire({
              title: "錯誤",
              type: "error",
              text: response.data.message,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    checkPermission (permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
  },
};
</script>
