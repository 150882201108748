<template>
  <div class="row">
    <div class="col-5 function-block mr-2"
      v-if="hasFilterHistoryPermission || hasFilterSearchPermission || hasFilterBoundTypePermission"
    >
      <div class="p-3">
        <h6>篩選條件</h6>
        <b-form-group v-if="hasFilterHistoryPermission">
          <b-form-radio-group
            v-model="filter.doesBound"
            :options="bindOptions"
            @change="triggerFilter"
          ></b-form-radio-group>
        </b-form-group>
        <AppMultiSelect
          v-if="hasFilterBoundTypePermission"
          v-model="filter.selectedBoundTypes"
          :options="filterBoundTypes"
          @input="triggerFilter"
        />
        <b-input-group class="mt-3" v-if="hasFilterSearchPermission">
          <b-form-input
            :placeholder="searchPlaceholder"
            v-model="filter.keyword"
            v-on:keyup.enter="triggerFilter"
          ></b-form-input>
          <b-input-group-append
            ><b-button @click="triggerFilter"
              ><i class="fa fa-search"></i></b-button
          ></b-input-group-append>
        </b-input-group>
      </div>
    </div>

    <div
      v-if="hasTransferPermission || hasUnbindPermission || hasChangeTypePermission"
      class="col-5 function-block"
    >
      <div class="p-3">
        <h6>操作</h6>
        <b-form class="mb-3"
          v-if="hasTransferPermission"
          inline
        >
          <b-form-radio
            v-model="operator.actionMode"
            value="change-staff"
          ></b-form-radio>
          <label class="mr-2">業務轉移</label>
          <b-form-group class="mr-2">
            <b-form-select
              v-model="operator.changeToStaffId"
              :disabled="checkDisabled('change-staff')"
            >
              <b-form-select-option :value="null">請選擇業務</b-form-select-option>
              <b-form-select-option
                v-for="staff in staffs"
                :key="staff.id"
                :value="staff.id"
              >
                {{ staff.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-button
            variant="primary"
            @click="$emit('trigger-change-staff')"
            :disabled="checkDisabled('change-staff')"
          >
            確認業務轉移
          </b-button>
        </b-form>

        <b-form class="mb-3"
          v-if="hasUnbindPermission"
          inline
        >
          <b-form-radio
            v-model="operator.actionMode"
            value="unbind-staff"
          ></b-form-radio>
          <label class="mr-2">解除綁定</label>
          <b-button
            variant="primary"
            @click="$emit('trigger-unbind')"
            :disabled="checkDisabled('unbind-staff')"
            >確認解除綁定</b-button
          >
        </b-form>

        <b-form
          v-if="hasChangeTypePermission"
          inline
        >
          <b-form-radio
            v-model="operator.actionMode"
            value="change-bound-type"
          ></b-form-radio>
          <label class="mr-2">變更類型</label>
          <b-form-group class="mr-2">
            <b-form-select
              v-model="operator.changeBoundType"
              :disabled="checkDisabled('change-bound-type')"
            >
              <b-form-select-option :value="null"
                >請選擇綁定類型</b-form-select-option
              >
              <b-form-select-option
                v-for="boundType in canChangeBoundTypes"
                :key="boundType.value"
                :value="boundType.value"
                >{{ boundType.text }}</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
          <b-button
            variant="primary"
            @click="$emit('trigger-change-bound-type')"
            :disabled="checkDisabled('change-bound-type')"
            >確認變更類型</b-button
          >
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import AppMultiSelect from "@/components/AppMultiSelect";
import staffApi from "@/apis/staff";
import * as consts from "@/consts";

export default {
  components: { AppMultiSelect },
  props: {
    filter: {
      doesBound: true,
      selectedBoundTypes: [],
      keyword: null,
    },
    operator: {
      actionMode: "change-staff",
      changeToStaffId: null,
      changeBoundType: null,
    },
    boundTypes: [],
    textMapping: [],
  },
  data() {
    return {
      bindOptions: [
        { text: "綁定中", value: true },
        { text: "綁定紀錄", value: false },
      ],
      staffs: [],
    };
  },
  computed: {
    hasFilterHistoryPermission() {
      return this.$permissions.hasAny([
        consts.CUSTOMER_STAFF_FILTER_HISTORY
      ]);
    },
    hasFilterBoundTypePermission() {
      return this.$permissions.hasAny([
        consts.CUSTOMER_STAFF_FILTER_BOUND_TYPE
      ]);
    },
    hasFilterSearchPermission() {
      return this.$permissions.hasAny([
        consts.CUSTOMER_STAFF_FILTER_SEARCH
      ]);
    },
    hasTransferPermission() {
      return this.$permissions.hasAny([
        consts.CUSTOMER_STAFF_TRANSFER
      ]);
    },
    hasUnbindPermission() {
      return this.$permissions.hasAny([
        consts.CUSTOMER_STAFF_UNBIND
      ]);
    },
    hasChangeTypePermission() {
      return this.$permissions.hasAny([
        consts.CUSTOMER_STAFF_CHANGE_TYPE
      ]);
    },
    searchPlaceholder() {
      if (this.textMapping.length) {
        const customer = this.textMapping.find((tm) => { return tm.key === 'customer.name' })['display_name']
        return `搜尋 ${customer}`
      }
      return "搜尋姓名/平台ID"
    },
    filterBoundTypes() {
      return this.boundTypes
        .filter((boundType) => {
          return boundType.can_show_filter;
        })
        .map((boundType) => {
          return { value: boundType.key, text: boundType.display_name };
        });
    },
    canChangeBoundTypes() {
      return this.boundTypes
        .filter((boundType) => {
          return boundType.can_change_type;
        })
        .map((boundType) => {
          return { value: boundType.key, text: boundType.display_name };
        });
    },
  },
  mounted() {
    if (this.hasTransferPermission) {
      this.getAllStaffs();
    }
  },
  methods: {
    checkDisabled(actionMode) {
      return (
        this.filter.doesBound == false || actionMode != this.operator.actionMode
      );
    },
    async getAllStaffs() {
      try {
        const { data } = await staffApi.getManagedStaffs({
          is_all: true,
          role: "staff",
          is_enabled: true,
        });
        this.staffs = data.data;
      } catch (e) {
        console.log(e);
      }
    },
    triggerFilter() {
      this.$emit("trigger-filter");
    },
  },
};
</script>

<style scoped lang="scss">
.function-block {
  border-color: #dee2e694;
  border-style: solid;
}
</style>
